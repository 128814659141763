import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [

  {
    path: 'home',
    loadChildren: () => import('../../container/container.module').then(m => m.ContainerModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('../../pages/pages.module').then(m => m.PagesModule)
  },
  // ,
  // {
  //   path: 'roles',
  //   loadChildren: () => import('../../roles/roles.module').then(m => m.RolesModule)
  // }
  // ,
  // {
  //   path: 'country',
  //   loadChildren: () => import('../../countrys/countrys.module').then(m => m.CountrysModule)
  // }
  // ,
  // {
  //   path: 'coins',
  //   loadChildren: () => import('../../coins/coins.module').then(m => m.CoinsModule)
  // }
  // ,
  // {
  //   path: 'contracts',
  //   loadChildren: () => import('../../contracts/contracts.module').then(m => m.ContractsModule)
  // },
  // {
  //   path: 'offers',
  //   loadChildren: () => import('../../offers/offers.module').then(m => m.OffersModule)
  // }
];
