import { Component, OnInit } from '@angular/core';
import { SettingService } from 'src/app/services/setting.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  list_social_media:any
  facebook:any
  twitter:any
  whatsapp:any
  instagram:any
  linkedin:any
  youtube:any

  constructor(private setting_service: SettingService) { }

  ngOnInit():void{
    // this.get_all_social_media()
  }
  
  // get_all_social_media(){
  //   this.setting_service.get_all_social_media().subscribe((res:any)=>{
  //     // console.log("list social media ",res)
  //     this.list_social_media=res

  //     this.facebook=res.filter(el=> el.name==="facebook")[0]
  //     this.twitter=res.filter(el=> el.name==="twitter")[0]
  //     this.whatsapp=res.filter(el=> el.name==="whatsapp")[0]
  //     this.instagram=res.filter(el=> el.name==="instagram")[0]
  //     this.linkedin=res.filter(el=> el.name==="linkedin")[0]
  //     this.youtube=res.filter(el=> el.name==="youtube")[0]

  //     console.log('facebook', this.facebook.value)

  //   })
  // }

}
