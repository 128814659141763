import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ContentComponent } from "./layouts/content/content.component";
import { FullComponent } from "./layouts/full/full.component";
import { TopbarComponent } from "./shared/topbar/topbar.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { FilterPipe } from "./pipes/filter.pipe";
import { NgxPaginationModule } from "ngx-pagination"; // <-- import the module
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ToastNotificationsModule } from "ngx-toast-notifications";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TestComponent } from "./test/test.component";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { Ng2TelInputModule } from "ng2-tel-input";
import { EventsModule, EventsService } from "angular4-events";
import { CookieModule } from "ngx-cookie";
import { AgmCoreModule } from "@agm/core";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    ContentComponent,
    FullComponent,
    TopbarComponent,
    FooterComponent,
    FilterPipe,
    TestComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    Ng2TelInputModule,
    GooglePlaceModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    ToastNotificationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAfdjYErHyXDEfgvAkjYsroRLeSGmvYUh0',
      language: 'en'

    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxPaginationModule,
    EventsModule.forRoot(),
    CookieModule.forRoot()
  ],
  providers: [EventsService],
  bootstrap: [AppComponent],
})
export class AppModule {}
