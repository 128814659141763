import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {

  constructor() { }
  
  ngOnInit():void{


    // this.loadExternalScript("assets/js/jquery.min.js")
    // this.loadExternalScript("assets/js/bootstrap.bundle.min.js")
    // this.loadExternalScript("assets/js/jquery.meanmenu.js")
    // this.loadExternalScript("assets/js/owl.carousel.min.js")
    // this.loadExternalScript("assets/js/jquery.appear.js")
    // this.loadExternalScript("assets/js/odometer.min.js")
    // this.loadExternalScript("assets/js/jquery.magnific-popup.min.js")
    // this.loadExternalScript("assets/js/fancybox.min.js")
    // this.loadExternalScript("assets/js/jquery-ui.js")
    // this.loadExternalScript("assets/js/selectize.min.js")
    // this.loadExternalScript("assets/js/TweenMax.min.js")
    // this.loadExternalScript("assets/js/aos.js")
    // this.loadExternalScript("assets/js/jquery.ajaxchimp.min.js")
    // this.loadExternalScript("assets/js/form-validator.min.js")
    // this.loadExternalScript("assets/js/contact-form-script.js")
    // this.loadExternalScript("assets/js/wow.min.js")
    // this.loadExternalScript("assets/js/main.js") 
  
  
    }
  
  
    public loadExternalScript(url: string) {
      const body = <HTMLDivElement>document.body;
      const script = document.createElement('script');
      script.innerHTML = '';
      script.src = url;
      script.async = true;
      script.defer = true;
      body.appendChild(script);
    }

}
