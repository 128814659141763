import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CardService {

  cardService_endpoint=environment.baseUrl+ "/card" 
  url=environment.baseUrl+ "/users/check/existence"


  constructor(private httpClient:HttpClient) { }

  get_all_cards(): Observable<any> {

    return this.httpClient.get<any>(this.cardService_endpoint).pipe(
      map((res: any) => {

        if (res.Status === 200) {
         return res.Data;
        }
      })
      
    );
  }

  get_card_by_id(id): Observable<any> {
    return this.httpClient.get<any>(this.cardService_endpoint+"/"+id).pipe(
      map((res: any) => {
        if (res.Status === 200) {
          console.log(res);
          return res['Data'];
        }
      })
    );
  } 

  check_card_validation(card_verficiation:any): Observable<any> {

    return this.httpClient.post<any>(this.url, card_verficiation).pipe(
      map((res: any) => {

        return res

        // if (res.status === 200) {
        //  return res;
        // }
      })
    );
  }


}
