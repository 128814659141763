import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'webttakafol';
  textDir: string = 'rtl';
  currentLanguage

  constructor(private translate: TranslateService) {


    const lang = this.translate.currentLang;
    console.log('langue', lang)
    console.log('langue localstorage : ', localStorage.getItem("lang"))

    if (lang!=undefined){
     
    localStorage.setItem("lang",lang)
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    } else

    localStorage.setItem("lang","ar")
    this.translate.setDefaultLang("ar");
    this.translate.use("ar");


     this.translate.onLangChange.subscribe((event: LangChangeEvent) => {

      console.log("on change language est lng", event.lang)

      this.currentLanguage = event.lang;

      if (this.currentLanguage == 'ar') {
        this.textDir = 'rtl';
      }
      else {
        this.textDir = 'ltr';
      }
    }
    ) 


  }
  ngOnInit(): void {

  }


  public loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }


}
