import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/internal/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {

  private JWT_TOKEN = 'JWT_TOKEN';
  private REFRESH_TOKEN = 'REFRESH_TOKEN';
  private Endpoint_login = environment.baseUrl + 'auths/admins/signin';

  private loggedUser: string;

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;


  public currentLanguageSubject: BehaviorSubject<any>;
  public currentLanguage: Observable<any>; 

  constructor(private httpClient: HttpClient, private router: Router) {

    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

    this.currentLanguageSubject = new BehaviorSubject<any>("ar");
    this.currentLanguage = this.currentLanguageSubject.asObservable();

  }


  get currentUserValue(): any {
    return this.currentUserSubject.value;
  }


  login(context: LoginContext) {
    const loginRequest = {
      email: context.email,
      password: context.password,
    };

    return this.httpClient.post(this.Endpoint_login, loginRequest).pipe(
      map(user => {
        console.log(user)
        localStorage.setItem('currentUser', JSON.stringify(user['data']['admin']));
        this.currentUserSubject.next(user['data']['admin']);
        this.doLoginUser(user['data'])
        this.router.navigate(['/home'])
      },
        error => {
          console.log(error.error)
        })
    )

  }
  LogOut() {
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigate(['/pages/login'])

    }
   isLoggedin() {
    return !!this.getJwtToken()

   }

   getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
   }

   private doLoginUser(tokens) {
    this.storeTokens(tokens);
   }

   private doLogoutUser() {
    this.removeToken();
   }

    private getRefreshToken() {
    console.log('this.REFRESH_TOKEN', localStorage.getItem(this.REFRESH_TOKEN))
    return localStorage.getItem(this.REFRESH_TOKEN);
    }

    private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
    }

    private storeTokens(tokens) {

    localStorage.setItem(this.JWT_TOKEN, tokens.token);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refresh_token);

    }

   removeToken() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem('currentUser');
   }



}
