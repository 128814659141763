import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import {FullComponent} from './layouts/full/full.component';
import {ContentComponent} from './layouts/content/content.component';
import {Full_ROUTES} from './shared/routes/full-layout.routes';
import {CONTENT_ROUTES} from './shared/routes/content-layout.routes';
import { Error404Component } from './pages/error404/error404.component';


const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  useHash: true,
  anchorScrolling: 'enabled'
  // scrollOffset: [0, 64],
};
const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  { path: '', component: FullComponent, data: { title: 'full Views' }, children: Full_ROUTES },
  { path: '', component: ContentComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes,routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
