import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { EventsService } from "angular4-events";
import { CookieService } from "ngx-cookie";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthentificationService } from "src/app/services/authentification.service";
import { CardService } from "src/app/services/card.service";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})
export class TopbarComponent implements OnInit {
  closeResult = "";
  collapsed = true;
  navbarCollapsed = true;
  scrollInterval = undefined;
  showlog = false;
  // textDir;

  @ViewChild("stickyMenu", { static: true }) menuElement: ElementRef;
  @ViewChild("close", { static: false }) close: ElementRef;

  @ViewChild("editModal", { static: true }) openModal: TemplateRef<any>; // Note: TemplateRef

  parent = document.querySelector("#open");

  sticky: boolean = false;
  elementPosition: any;
  lastScroll = false;
  check_Form_message: any = "";
  check_Form_card: any = [];
  client: any;
  customer_card: any;
  english_logo: boolean = false;
  arabic_logo: boolean = true;
  isCollapsed = true;
  textDir: string = 'rtl';

  constructor(
    private modalService: NgbModal,
    private authentification_service: AuthentificationService,
    private events: EventsService,
    private translate: TranslateService,
    private cookieService: CookieService,
    private cardservice: CardService,
    private router: Router
  ) {
    /// parent.addEventListener("click", (e) => this.choise(e));

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang == "ar") {
        this.textDir = "rtl";
        console.log('rtl')
        this.arabic_logo = true;
      } else {
        this.textDir = "ltr";
        this.english_logo = false;
        console.log('ltr')
      }
    });
  }

  ngOnInit(): void {
    this.loadExternalScript("assets/js/jquery.min.js");
    this.loadExternalScript("assets/js/bootstrap.bundle.min.js");
    this.loadExternalScript("assets/js/jquery.meanmenu.js");
    this.loadExternalScript("assets/js/owl.carousel.min.js");
    this.loadExternalScript("assets/js/jquery.appear.js");
    this.loadExternalScript("assets/js/odometer.min.js");
    this.loadExternalScript("assets/js/jquery.magnific-popup.min.js");
    this.loadExternalScript("assets/js/fancybox.min.js");
    this.loadExternalScript("assets/js/jquery-ui.js");
    this.loadExternalScript("assets/js/selectize.min.js");
    this.loadExternalScript("assets/js/TweenMax.min.js");
    this.loadExternalScript("assets/js/aos.js");
    this.loadExternalScript("assets/js/jquery.ajaxchimp.min.js");
    this.loadExternalScript("assets/js/form-validator.min.js");
    this.loadExternalScript("assets/js/contact-form-script.js");
    this.loadExternalScript("assets/js/wow.min.js");
    this.loadExternalScript("assets/js/main.js");
  }

  toggleNavbarCollapsing() {
    this.navbarCollapsed = !this.navbarCollapsed;
  }

  public loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement("script");
    script.innerHTML = "";
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  open(content) {
    this.modalService.open(content, { centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  /*
  choise(e) {
    console.log("element ", e.target["innerText"],"الإنجليزية");
    console.log(e.target["innerText"].trim().localeCompare("الإنجليزية"))
    if (e.target["innerText"].trim().localeCompare("التحقق من العضوية")===0) {
       this.modalService.open(this.openModal, { centered: true }).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    }
    if (e.target["innerText"].trim().localeCompare("English")===0) {
      this.change_Language("en");

    }
    if (e.target["innerText"].trim().localeCompare("الإنجليزية")===0) {
       this.change_Language("en");
    }
    if (e.target["innerText"].trim().localeCompare("Arabic")===0) {
      this.change_Language("ar");
    }
    if (e.target["innerText"].trim().localeCompare("العربية")!=-1) {
      this.change_Language("ar");
    }
    this.getLang()
   /// this.isCollapsed=true;
  } */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  private change_Language(lng: any) {
    console.log("change_Language ", lng);
    this.translate.setDefaultLang(lng);
    this.translate.use(lng);
    console.log("language", lng);
    this.events.publish("lang", lng);
    this.cookieService.put("lang", lng);
    localStorage.setItem("lang", lng);
  }

  toggle() {
    this.navbarCollapsed = !this.navbarCollapsed;
    this.sticky = true;
    this.showlog = true;
  }

  ngAfterViewInit() {
    if (this.menuElement != undefined)
      this.elementPosition = this.menuElement.nativeElement.offsetTop;
  }

  @HostListener("window:scroll", ["$event"])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.elementPosition) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }

  @HostListener("window:unscroll", ["$event"])
  handleUnScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll <= this.elementPosition) {
      this.sticky = false;
    } else {
      this.sticky = true;
    }
  }

  chech_vard_verification(value: any) {
    console.log("customer", value);
    // this.close.nativeElement.click();

    this.cardservice
      .check_card_validation({ customer: value })
      .subscribe((res) => {
        this.check_Form_message = res.message;
        this.check_Form_card = res.data;

        console.log("data", res);
      });
  }

  open2(content, value) {
    console.log("enter value ", value);
    this.cardservice
      .check_card_validation({ customer: value })
      .subscribe((res) => {
        console.log("res", res);
        this.check_Form_message = res.message;
        this.check_Form_card = res.data;

        console.log("data of verification ****** ", res);
        if ((res.data.message = "all  cards ")) {
          this.client = res.data[0].customer.user_name;
          console.log("customer ******************", this.client);
          localStorage.setItem("token", res.token);
          localStorage.setItem("refresh_token", res.token);
        }
      });

    this.modalService.open(content, { centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  reloadHomePage() {
    this.router.navigate(["/home"]).then(() => {
      window.location.reload();
    });
  }

  getLang() {
    if (localStorage.getItem("lang") == "ar") {
      this.textDir = "rtl";
    } else {
      this.textDir = "ltr";
    }

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      console.log(
        "lang from localStorge ",
        localStorage.getItem("lang"),
        this.textDir
      );
    });
  }
}
