import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  setting_endPoint=environment.baseUrl+ "/socialmedias"

  constructor(private httpClient:HttpClient) { }

  get_all_social_media(): Observable<any[]> {

    return this.httpClient.get<any[]>(this.setting_endPoint).pipe(
      map((res: any) => {
        console.log('data from setting',res)
        
        if (res.status === 200) {
         return res.data;
        }
      })
    );
  } 
 
}
